<template>
        <div :class="['footer', { 'floating-footer': enableFloat }]">
                <span>
                        <!-- TODO(aes): make this dynamic -->
                        <a href="/terms_and_conditions">Terms and Conditions</a>
                        | Version 4.0.070cc318f |
                        | Copyright © 2019, All rights reserved.
                </span>
                <div id="footer-logo">
                        Powered by
                        <img src="~/assets/images/client/globe_labs.svg">
                </div>
        </div>
</template>

<script>
export default {
        data () {
                return {
                        enableFloat: false
                }
        },

        created () {
                this.enableFloat = false
                if (this.$route.name === 'profiles-user_id-welcome_dashboard') {
                        this.enableFloat = true
                }
        }
}
</script>


<style scoped>
.floating-footer {
    position: relative;
    z-index: 10; /* Adjust the z-index as needed */
}

.floating-footer a {
    position: relative;
    z-index: 20; /* Ensure the link has a higher z-index */
}
</style>